<template>
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 8.44273H1.875M12.7083 1.77606V5.1094M6.04167 1.77606V5.1094M6.875 13.4427L8.54167 15.1094L12.2917 11.3594M5.875 18.4427H12.875C14.2751 18.4427 14.9752 18.4427 15.51 18.1702C15.9804 17.9306 16.3628 17.5481 16.6025 17.0777C16.875 16.5429 16.875 15.8429 16.875 14.4427V7.44273C16.875 6.0426 16.875 5.34253 16.6025 4.80775C16.3628 4.33735 15.9804 3.9549 15.51 3.71521C14.9752 3.44273 14.2751 3.44273 12.875 3.44273H5.875C4.47487 3.44273 3.7748 3.44273 3.24002 3.71521C2.76962 3.9549 2.38717 4.33735 2.14748 4.80775C1.875 5.34253 1.875 6.0426 1.875 7.44273V14.4427C1.875 15.8429 1.875 16.5429 2.14748 17.0777C2.38717 17.5481 2.76962 17.9306 3.24002 18.1702C3.7748 18.4427 4.47487 18.4427 5.875 18.4427Z"
      stroke="currentcolor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
